import {Card, CardActionArea, CardContent, CardMedia, ImageList, ImageListItem, Typography } from "@mui/material";
import { useData } from "../Providers/DataProvider";
import { Link as RouterLink } from 'react-router-dom';

function tofixed(num:number) {return num.toFixed(2)}

export interface IAppProps {
}

export default function Showcase (props: IAppProps) {
    const {nearbyBusinesses} = useData();
    // const [products, setProducts] = useState<any[]|undefined>(undefined)
    const list1 = [
    {cols:4, rows:2},
    {cols:2, rows:2},
    {cols:2, rows:4},
    {cols:2, rows:2},
    {cols:4, rows:2},
    {cols:4, rows:2},
    ];
    const list = nearbyBusinesses?.map((item)=>({...item, cols:4, rows:2}));

    return (
    <ImageList variant="quilted" style={{paddingBlock: 5}} gap={10} cols={4} rowHeight={50}>
        {list ? list.map((item, index)=>(<ImageListItem style={{borderRadius:25}} key={index} cols={item.cols}  rows={item.rows}>
            <Card style={{height: "100%"}}>
                <CardActionArea component={RouterLink} to={'Business/'+item._id} state={{...item, _id:item._id.toString()}} style={{height: "100%", position:"relative"}}>
                    <CardMedia
                    style={{ position: "absolute",
                    top: 0,
                    right: 0,
                    height: "100%",
                    width: "100%"}}
                    component="img"
                    height="100"
                    image="https://source.unsplash.com/random"
                    alt="green iguana"
                    />
                    <CardContent style={{backgroundColor: "transparent", position:"relative"}}>
                        <Typography gutterBottom variant="h5" component="div">
                            {item.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {tofixed(item.dist/1000)} km
                        </Typography>
                    </CardContent>
                </CardActionArea>
           </Card>
        </ImageListItem>)) : 'Loading'}
    </ImageList>
  );
}
