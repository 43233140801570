import { Box, Collapse, Container, Slide } from "@mui/material";
import Greating from "../../../Components/Greating";
import MainNavBar from "../../../Components/MainNavBar";
import QuickAccess from "../../../Components/QuickAccess";
import Showcase from "../../../Components/Showcase";
import { useData } from "../../../Providers/DataProvider";
import { ReactElement, useState } from "react";

export interface IHomeProps {
}

export default function Home (props: IHomeProps) {
  const {loading} = useData();
  const [node, setNode] = useState<Node|undefined>(undefined);
  
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <MainNavBar scrollNode={node}/>
      <QuickAccess/>
      <Container ref={(n:any)=>{if(n){setNode(n);}}} sx={{ py:1, overflow:'overlay', overflowX:'hidden', /**/flex: 1}} >
        <Greating />
        {!loading?<Showcase/>:'Loading'}
      </Container>
    </Box>
  );
}