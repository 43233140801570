import { Box, Button, Container } from '@mui/material'
import { ChangeEvent, ReactElement, useState } from 'react'
import { styled } from '@mui/material/styles';
import Navbar2 from '../../Components/Navbar2';
import { useData } from '../../Providers/DataProvider';

interface Props {
    
}

const MAX_IMAGE_SIZE = 1000000

const Input = styled('input')({
    display: 'none',
  });



export default function Settings({}: Props): ReactElement {
    // const [blob, setBlob] = useState<undefined|any>(undefined);
    const [selectedFile, setSelectedFile] = useState<undefined|any>(undefined);
    const [selectedFileName, setSelectedFileName] = useState<undefined|String>(undefined);
    const {profileImgUploadURL} = useData();

    const onFileChange =  (event:any)=>{
        if(!event?.target?.files?.[0]){
            alert('no file selected');
            console.log('no file selected');
            return;
        }

        let reader = new FileReader()
    
        reader.onload = (e:ProgressEvent<FileReader>) => {
            if(!e)
            return;

            // if (!e.target?.result?.toString().includes('data:image/jpeg')) {
            //     return alert('Wrong file type - JPG only.')
            // }
            if (
                e.target?.result?.toString()?.length
                &&
                (e.target?.result?.toString()?.length > MAX_IMAGE_SIZE)
            ){
                return alert('Image is loo large.')
            }
            setSelectedFile(e.target?.result || undefined);
            setSelectedFileName(event?.target?.files[0].name);
        }

        reader.readAsDataURL(event?.target?.files?.[0]);      
    }

    const uploadFile = async ()=>{
        if(!selectedFile){
            alert('no file selected');
            console.log('no file selected');
            return;
        }
        
        let splits = selectedFile.split(',');

        let binary = atob(splits[1]);
        let type = splits[0].split(';')[0].split(':')[1];

        let array = []
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
        }

        let blobData = new Blob([new Uint8Array(array)], {type: type})

        let uploadURL = await profileImgUploadURL(selectedFileName);

        // const result = 
        if(uploadURL){
            fetch(uploadURL, {
                method: 'PUT',
                body: blobData
            }).then(
                ()=>console.log('uploaded'),
                (e:any)=>console.log(`failed to upload error: ${e}`)
            )
        }
        // console.log(result);
    }

    return (
        <Box display="flex" flexDirection="column" height="100vh" >            
            <Navbar2 heading={'Settings'} quickaccess={false}/>
            <Container sx={{overflow:'overlay', py:1, flex: 1}} >
                <label>
                <Input accept="image/*" onChange={onFileChange} id="contained-button-file" multiple type="file" />
                <Button variant="contained" component="span">
                    Select
                </Button>
                </label>
                <Button onClick={uploadFile} variant="contained" component="span">
                    Upload
                </Button>
            </Container>
        </Box>
    )
}
