import { Box, Container, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import Navbar2 from "../../../Components/Navbar2";
import { Link as RouterLink } from 'react-router-dom';

export interface IProfileProps {
}

export default function Profile (props: IProfileProps) {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Navbar2/>
      <Container style={{overflow:'overlay', flex: 1}} >
      <List>
          <ListItem disablePadding>
            <ListItemButton component={RouterLink} to='/settings'>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Orders" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Personal information" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Addresses" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Payments" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Chats" />
            </ListItemButton>
          </ListItem>
      </List>
      </Container>
    </Box>
  );
}
