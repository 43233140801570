
interface IAppProps {
}

export default function ForgotPassword (props: IAppProps) {
  return (
    <div>
      Forgot Password
    </div>
  );
}
