import { Box, Container } from '@mui/material';
import * as React from 'react';
import MainNavBar from '../../../Components/MainNavBar';
import { GoogleMap, Marker, Circle, InfoWindow, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
import { useData } from '../../../Providers/DataProvider';
import DotMarker from '../../../Assets/DotMarker.svg';
import dotCurr from '../../../Assets/dotCurr.svg';
import { useTheme } from '@mui/material/styles';

export interface INearbyProps {
  hide?:Boolean;
  load?:React.Dispatch<React.SetStateAction<boolean>>;
}

// Center on india
export const center1 = {
  lat: 20.5937,
  lng: 78.9629
};

// Disable default UI
const options = (mode:string):google.maps.MapOptions => ({
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  // mapId: '4e3be8d544d2534c' //(dayMode) 
  mapId: mode==='dark' ? '652b05d9967fd6bb':'4e3be8d544d2534c' //(nightMode)
});

const cOptions = {
  strokeColor: '#357adb',
  strokeOpacity: 0.2,
  strokeWeight: 2,
  fillColor: '#357adb',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  // zIndex: 1
}
// const mcOptions = {
//   imagePath:'../../../Assets/DotMarker3.svg', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
// }

export default function Nearby (props: INearbyProps) {
  const {hide, load} = props;
  const {nearbyBusinesses} = useData();
  const theme = useTheme();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY!
  });
  const [currentPosition, setCurrentPosition] = React.useState<{lat: number, lng:number}|undefined>();
  const [center, setCenter] = React.useState<{lat: number, lng:number}|undefined>(undefined);
  const [posAccuracy, setPosAccuracy] = React.useState<number|undefined>();
  const [selectedMarker, setSelectedMarker] = React.useState<any>();
  // const scrollRef = React.useRef<HTMLDivElement | null>(null);

  var id:number;

  React.useEffect(() => {
    if(load) load(true);
    id = navigator?.geolocation.watchPosition(
      ({ coords: { latitude: lat, longitude: lng, accuracy: acc } }) => {
        const pos = { lat, lng };
        setCurrentPosition(pos);
        setPosAccuracy(acc);
        if(!center)setCenter(pos||undefined);
      }
    );
    return () => {
      navigator?.geolocation.clearWatch(id)
    }
  }, [])
  
  // Save map in ref if we want to access the map
  const mapRef = React.useRef<google.maps.Map | null>(null);
  
  // const onLoad = (map: google.maps.Map): void => {
  //   // mapRef.current = map;
  // };
  
  
  const onLoad = (map: google.maps.Map):void => {
    mapRef.current = map;
    
  }
  
  const onUnMount = (): void => {
    mapRef.current = null;
  };

  const onMapClick = (): void => {
    // if (scrollRef.current) {
    //   scrollRef.current.scrollIntoView({ behavior: "auto" });
    // }
  };

  return (
    <Box position={hide?'absolute':'static'} display={hide?"none":"flex"} flexDirection="column" height="100%" >
      <MainNavBar/>
      {/* <QuickAccess/> */}
      <Container sx={{overflow:'overlay', flex: 1, px:0}} >
        {!isLoaded? "loading" : <GoogleMap
          mapContainerStyle={{width:'100%', height:'100%'}}
          center={center||center1}
          options={options(theme.palette.mode) as google.maps.MapOptions}
          zoom={center? 16 : 4}
          onLoad={onLoad}
          onUnmount={onUnMount}
          onClick={onMapClick}
        >
          {currentPosition&&(<>
            <Marker
              key={'Curret Location'}
              position={currentPosition}
              icon={{
                url:dotCurr,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(5, 5),
                scaledSize: new window.google.maps.Size(10, 10)
              }}/>            
            {posAccuracy&&<Circle
              center={currentPosition}
              radius={posAccuracy}
              options={cOptions}
            />}
          </>)}
          <MarkerClusterer  >
            {(clusterer) =>
              nearbyBusinesses?.map((business)=>{
              return <Marker
                onClick={()=>setSelectedMarker(business)}
                clusterer={clusterer}
                icon={{
                  url:DotMarker,
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                  scaledSize: new window.google.maps.Size(30, 30)
                }}
                key={business?._id}
                position={{
                  lat:business.geoLocation.coordinates[1],
                  lng:business.geoLocation.coordinates[0]
                }}
              >
              </Marker>})
            }
          </MarkerClusterer>
          {selectedMarker&&<InfoWindow
          onCloseClick={()=>setSelectedMarker(undefined)}             
            position={{
              lat:selectedMarker.geoLocation.coordinates[1],
              lng:selectedMarker.geoLocation.coordinates[0]
            }}
          >
            <div>{selectedMarker.name||'mmm'}</div>
          </InfoWindow>}
        </GoogleMap>        
        }
        {/* <div ref={scrollRef}/> */}
      </Container>
    </Box>
  );
}
