import React, { createContext, useContext, useEffect, useState } from "react";
import * as Realm from "realm-web";
import { useAuth } from './AuthProvider';

interface IWrapper {
  loading: boolean;
  nearbyBusinesses: any[] | undefined;
  businessesNearby: () => Promise<any[] | undefined>;
  profileImgUploadURL: (name?:String) => Promise<string | undefined>;
  businessDetails: (_id:Realm.BSON.ObjectId) => Promise<any[] | undefined>;
  productDetails: (_id:Realm.BSON.ObjectId) => Promise<any[] | undefined>;
  businessProducts: (businessId:Realm.BSON.ObjectId, skip?:number|undefined) => Promise<any[] | undefined>;
  productsNearby: () => Promise<any[] | undefined>;
  productAutoComplete: (text:String) => Promise<any[] | undefined>;
}

const DataContext = createContext<IWrapper | null>(null);

export const DataProvider:React.FC = ({ children }) => {
  const {currentUser} = useAuth();
  const [location, setLocation] = useState<number[] | undefined>([79.1228256, 29.3994925])
  const [loading, setLoading] = useState(true)
  const [nearbyBusinesses, setNearbyBusinesses] = useState<any[]>()
  const [nearbyBusinessIds, setNearbyBusinessIds] = useState<Realm.BSON.ObjectId[] | undefined>()

  useEffect(() => {
    businessesNearby().finally(()=>setLoading(false));
  },[])

  async function businessDetails(_id:Realm.BSON.ObjectId) {
    if(!currentUser) throw 'currentuser not found';
    
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const businessCollection = mongodb.db("busivisy").collection("businesses");
    
    return await businessCollection.findOne({_id});
  }
  
  async function productDetails(_id:Realm.BSON.ObjectId) {
    if(!currentUser) throw 'currentuser not found';
    
    const mongodb = currentUser.mongoClient("mongodb-atlas");
    const businessCollection = mongodb.db("busivisy").collection("products");
    
    return await businessCollection.findOne({_id});
  }
  
  async function profileImgUploadURL(name?:String) {
    if(!currentUser) throw 'currentuser not found';
    // skip = !skip? 0:skip;

    return await currentUser.functions.getProfileImgUploadUrl(name||'NoName');
    
    // const mongodb = currentUser.mongoClient("mongodb-atlas");
    // const businessCollection = mongodb.db("busivisy").collection("products");
    
    // let cursor = await (await businessCollection.find({businessId: _id}));
    
    // return cursor;
  }

  async function businessProducts(businessId:Realm.BSON.ObjectId, skip=0) {
    if(!currentUser) throw 'currentuser not found';
    let limit= 10;
    // skip = !skip? 0:skip;

    return await currentUser.functions.getBusinessProducts(businessId, limit, skip);
    
    // const mongodb = currentUser.mongoClient("mongodb-atlas");
    // const businessCollection = mongodb.db("busivisy").collection("products");
    
    // let cursor = await (await businessCollection.find({businessId: _id}));
    
    // return cursor;
  }

  async function businessesNearby(){
    if(!currentUser) throw 'currentuser not found';

    let nearbyB:[{_id:Realm.BSON.ObjectId, name:string}] = await currentUser.functions.getBusinessesNearby(location, 500);
    setNearbyBusinesses(nearbyB);
    setNearbyBusinessIds(nearbyB.map(({_id})=>_id));
    return nearbyB;
  }

  async function productsNearby(){
    if(!currentUser) throw 'currentuser not found';

    let nearbyP = await currentUser.functions.getProductsNearby(nearbyBusinessIds);
    return nearbyP;
  }

  async function productAutoComplete(text:String){
    if(!currentUser) throw 'currentuser not found';
    if(text==='')
    return [];
    let autoComplete = await currentUser.functions.productAutocomplete(text,nearbyBusinessIds);
    return autoComplete;
  }
  
  const wrapped = {
    loading,
    nearbyBusinesses,
    businessesNearby,
    businessDetails,
    productDetails,
    profileImgUploadURL,
    businessProducts,
    productsNearby,
    productAutoComplete
  };

  return (
    <DataContext.Provider value={wrapped}>{!loading?children:'loading nearby businesses'}</DataContext.Provider>
    // <DataContext.Provider value={wrapped}>{children}</DataContext.Provider>
  );
};

export const useData = () => {
  const dataProvider = useContext(DataContext);
  if (!dataProvider) {
    throw new Error(`You must call useData() inside of a <DataProvider />`);
  }
  return dataProvider;
};
