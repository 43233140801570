import { Box } from "@mui/material";
import { Outlet } from "react-router";
import BottomNav from "../../../Components/BottomNavigation";

export interface IDashBoardProps {
}

export default function DashBoard (props: IDashBoardProps) {
  return (
    <Box display="flex" flexDirection="column" height="100vh"  /*overflow='overlay'*/>
      <Box flex={1} overflow='hidden'>
        <Outlet/>
      </Box>
      <BottomNav/>
    </Box>
  );
}
