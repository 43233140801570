import { ReactElement } from 'react'
import * as Realm from "realm-web";
// import { useTheme } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel'
import Box from '@mui/material/Box';
import { Card, CardMedia } from '@mui/material';
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from 'react-swipeable-views-utils';
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface Props {
    productId: Realm.BSON.ObjectId
}

const images = [
    {
      label: 'San Francisco – Oakland Bay Bridge, United States',
      imgPath:
        'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
      label: 'Bird',
      imgPath:
        'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
      label: 'Bali, Indonesia',
      imgPath:
        'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
    },
    {
      label: 'Goč, Serbia',
      imgPath:
        'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    },
  ];

export default function ProductImages({productId}: Props): ReactElement {
    // const theme = useTheme();
    // const [activeStep, setActiveStep] = useState(0);
    // const maxSteps = images.length;

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleStepChange = (step: number) => {
    //     setActiveStep(step);
    // };
    return (
      <Box sx={{width: 400, alignSelf:'center'}} >
        <Carousel
          autoPlay={false}
          swipe={true}
        >
          {images.map((step, index) => (
              <Card  key={step.label}>
                {/* {Math.abs(0 - index) <= 1 ? ( */}
                  <CardMedia
                    draggable={false}
                    component="img"
                    sx={{
                      height: 255,
                      // maxWidth: 400,
                      overflow: 'hidden',
                      width: '100%',
                    }}
                    image={step.imgPath}
                    alt={step.label}
                  />
                {/* ) : null} */}
              </Card>
            ))}
        </Carousel>
      </Box>
    )
}
