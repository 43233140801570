import { Avatar, Box, Button, Menu, MenuItem, MenuList, Rating, Stack, Typography } from '@mui/material'
import { ReactElement, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
    state: any|undefined
}

export default function BusinessHeader({state}: Props): ReactElement { 
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    return (
        <Box sx={{px:3, zIndex:0, boxShadow:3, display:'flex', alignItems: 'center', justifyContent:'space-between', py:2}}>
            <Stack alignItems='inherit' spacing={1.5} direction='row'>
                <Avatar
                    variant="square"
                    alt='Business logo'
                    src="https://source.unsplash.com/random"
                />
                <Typography whiteSpace='break-spaces'>{state?.name.split(" ").join('\n')}</Typography>
                <Rating
                    // size="large"
                    name="business-rating"
                    defaultValue={4.5}
                    precision={0.5}
                    readOnly
                />
            </Stack>
            <div>
                <Button
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    variant="text"
                    color= 'inherit'
                    endIcon={<KeyboardArrowDownIcon />}
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    Menu
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        disablePadding:true,
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuList dense>
                        <MenuItem onClick={handleClose}>Products</MenuItem>
                        <MenuItem onClick={handleClose}>About</MenuItem>
                        <MenuItem onClick={handleClose}>Location</MenuItem>
                    </MenuList>
                </Menu>
            </div>
        </Box>
    )
}
