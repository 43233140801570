import { Avatar, Box, Typography } from "@mui/material";
import { useAuth } from "../Providers/AuthProvider";

export interface IAppProps {
}

export default function Greating (props: IAppProps) {
    const {currentUser} = useAuth();
  return (
    <Box display="flex" alignItems="center" >
      <Avatar alt="profile picture" src="https://source.unsplash.com/random"/>
      <Typography mx={1} >Hi, {currentUser?.customData?.name?.first || 'Stranger'}</Typography>
    </Box>
  );
}
