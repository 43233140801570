import { AppBar, Box, IconButton, Toolbar, SwipeableDrawer, Button, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useState } from "react";
import { useAuth } from "../Providers/AuthProvider";

interface IAppProps {
  heading?: String;
  quickaccess?: Boolean;
}

export default function Navbar2 ({heading='Hi, prakhar', quickaccess=true}: IAppProps) {
  const {logOut}=useAuth();
  const[drawer, setDrawer] = useState(false);
  
  return (
    <Box>
      <AppBar elevation={0} position="static">
        <Toolbar>
          <IconButton
          onClick={()=>setDrawer(true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            display="flex"
            alignItems="centre"
            flex="1"
            >
              <Typography variant="h6" >
                  {heading}
              </Typography>
          </Box>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 2 }}
          >
            <ShoppingCartOutlinedIcon />
          </IconButton>
        </Toolbar>
        {quickaccess && <Toolbar sx={{px:1}}>
        <Box sx={{overflow:"overlay", py:0.5 , whiteSpace:"nowrap"}}>
      <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Sale</Button>
      <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Buy in commute</Button>
      <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Whats around</Button>
      <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Trending</Button>
      <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Whats around</Button>
      <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Trending</Button>
    </Box>
        </Toolbar> }
      </AppBar>
      <SwipeableDrawer open={drawer} onOpen={()=>setDrawer(false)} onClose={()=>setDrawer(false)}>
        <Box p={1} sx={{width: 250}}>
          <Button fullWidth variant="contained" color="inherit" onClick={()=>logOut()} >Sign out</Button>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}
