import {Box, Container, Typography } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import BusinessHeader from '../../Components/BusinessHeader';
import MainNavBar from '../../Components/MainNavBar';
import { useData } from '../../Providers/DataProvider';
import ProductsGrid from '../../Components/ProductsGrid';
import * as Realm from "realm-web";

export interface Props {
}

export default function BusinessPage(props: Props): ReactElement {
    let {state: routerState}:any|undefined = useLocation();
    const [node, setNode] = useState<Node|undefined>(undefined)
    const [businessD, setBusinessD] = useState<undefined | any>()
    // const [businessP, setBusinessP] = useState<undefined | any[]>()
    const {businessDetails} = useData();
    // let scroll = window.scrollY
    // console.log(window);
    

    useEffect(() => {
      if(!routerState)return;
      businessDetails( new Realm.BSON.ObjectId(routerState?._id))
      .then(
        (value)=>setBusinessD(value),
        ()=>setBusinessD(undefined)
      ).finally(()=>console.log('businessDetailsFetched'));
      // businessProducts(routerState._id)
      // .then(
      //   (value)=>setBusinessP(value),
      //   ()=>setBusinessP(undefined)
      // ).finally(()=>console.log('productsFetched'))
      return () => {
        setBusinessD(undefined);
        // setBusinessP(undefined);
      }
    }, [])

    return (
    <Box display="flex" flexDirection="column" height="100%">
      <MainNavBar scrollNode={node} />
      <BusinessHeader state={routerState} />
      <Container ref={n=>{if(n){setNode(n)}}} sx={{py:1, overflow: 'overlay', overflowX:'hidden', flex: 1}}>
        <Typography>
          About {routerState?.name} <br/>
          {
            JSON.stringify(businessD)//about business
          } 
        </Typography>
        <Box sx={{py:1}}>
          Products <br/>
          <ProductsGrid businessId={new Realm.BSON.ObjectId(routerState?._id)}/>
        </Box>
      </Container>
    </Box>
    )
}
