import React, { createContext, useContext, useState } from "react";
import * as Realm from "realm-web";
// import googleOneTap from 'google-one-tap';

interface IWrapper {
    // currentUserF: () => (Realm.User | null);
    currentUser: Realm.User | null;
    logOut: () => Promise<void>;
    googleSignin: () => Promise<any>;
    signinEmailPassword: (email: string, password: string) => Promise<Realm.User>;
    signUpEmailPassword: (email: string, password: string, name: {first:string; last:string}) => Promise<any>
}

const AuthContext = createContext<IWrapper | null>(null);

export const AuthProvider:React.FC = ({ children }) => {
  
  const app: Realm.App = new Realm.App({ id: process.env.REACT_APP_REALM_ID! });
  const [currentUser, setCurrentUser] = useState(app.currentUser);

  // function currentUserF () {
  //   return app.currentUser;
  // }

  //sign in with email and password
  async function signinEmailPassword (email:string, password: string) {
    let credentials = Realm.Credentials.emailPassword(email, password);
    const errorMessage = {code : 403, error: 'Current user and logged in user are different'};
    // return app.logIn(credentials);
    
    try {
      // Authenticate the user
      const user: Realm.User = await app.logIn(credentials);
      // `App.currentUser` updates to match the logged in user
      if (user.id !== app.currentUser?.id) 
      {throw errorMessage}
      console.log("signed In");
      setCurrentUser(user);
      return user;
    } catch (err: any) {
      console.log(`Failed to log in! ${err}.`);
      throw err;
    }
  }

  //sign Up with email and password
  async function signUpEmailPassword(email:string, password:string, name:{first:string; last:string}) {
    let credentials = Realm.Credentials.emailPassword(email, password);
    try {
      await app.emailPasswordAuth.registerUser(email, password);
      const user:Realm.User = await app.logIn(credentials);
      const usersCollection = user
        .mongoClient("mongodb-atlas")
        .db("busivisy")
        .collection("users");
      await usersCollection.updateOne(
        { _id: user.id },
        { $set: { name: name } },
        { upsert: true }
      );
      console.log("signed Up");
      setCurrentUser(user);
      return user;
    } catch (err: any) {
      console.log(`Failed Sign Up! ${err}.`);
      throw err;
    }
  }

  //Sign in with google
  async function googleSignin() {
    // const redirectUri = "http://localhost:5000/redirect"; // serve -s build
    const redirectUri = "http://localhost:3000/redirect"; //npm start
    // const redirectUri = "https://business.app.busivisy.com/redirect"; // Production
    const credentials = Realm.Credentials.google(redirectUri);

    // Calling logIn() opens a Google authentication screen in a new window.
    app.logIn(credentials).then((user) => {
      setCurrentUser(user);
      console.log(`Logged in with id: ${user.id}`);
    });

    // IMPORTENT call this when redirected ==>> Realm.handleAuthRedirect();
    // Realm.handleAuthRedirect();
  }

  //Log out
  async function logOut() {
    // Log out the currently active user
    try {
      await app.currentUser?.logOut();
      console.log('Logged out');
    } catch (err: any) {
      alert(`Failed Logout! ${err?.error}.`);
      console.log(`Failed Logout! ${err.message}.`);
    }
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    setCurrentUser(app.currentUser);
  }

  const wrapped = {
    // ...app,
    currentUser,
    signUpEmailPassword,
    signinEmailPassword,
    googleSignin,
    logOut,
  };

  return (
    <AuthContext.Provider value={wrapped}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authProvider = useContext(AuthContext);
  if (!authProvider) {
    throw new Error(`You must call useAuth() inside of a <AuthProvider />`);
  }
  return authProvider;
};
