import { Box, Container, Typography } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import * as Realm from "realm-web";
import MainNavBar from '../../Components/MainNavBar';
import ProductImages from '../../Components/ProductImages';
import { useData } from '../../Providers/DataProvider';

interface Props {
    
}

export default function ProductPage({}: Props): ReactElement {
    const {state: routerState}:any = useLocation();
    const [node, setNode] = useState<Node|undefined>(undefined);
    const [pDetails, setPDetails] = useState<any|undefined>(undefined);
    const {productDetails} = useData();

    useEffect(() => {
      productDetails(new Realm.BSON.ObjectId(routerState?._id)).then(
        (v)=>setPDetails(v),
        (e)=>console.log(e)        
      )
      return () => {
        setPDetails(undefined);
      }
    }, [routerState])

    return (
        <Box display="flex" flexDirection="column" height="100%">
          <MainNavBar scrollNode={node} />
          <Container
            ref={(n: any)=>{if(n){setNode(n)}}}
            sx={{
              py:1,
              overflow: 'overlay',
              overflowX:'hidden',
              flex: 1,
              display:'flex',
              flexDirection:'column'
            }}
          >
            <ProductImages productId={new Realm.BSON.ObjectId(routerState._id)}/>
            {JSON.stringify(pDetails)}
          </Container>
        </Box>
    )
}
