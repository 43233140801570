import { alpha, Box, Button, useTheme } from "@mui/material";

interface IAppProps {
}



export default function QuickAccess (props: IAppProps) {
  const theme = useTheme();
  return (
    <Box sx={{px: 1, py:0.5 , whiteSpace:"nowrap", backgroundColor:(theme.palette.mode === 'dark')?alpha( '#fff', .06):alpha( '#000', .015)}}>
      <Box style={{overflow:"overlay"}}>
        <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Sale</Button>
        <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Buy in commute</Button>
        <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Whats around</Button>
        <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Trending</Button>
        <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Whats around</Button>
        <Button color="inherit" sx={{textTransform:"none"}} onClick={()=>{}} variant="text">Trending</Button>
      </Box>
    </Box>
  );
}
