import './App.css';
import SignIn from './Screens/Auth/Signin';
import SignUp from './Screens/Auth/Signup';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet
} from "react-router";
import { AuthProvider, useAuth } from './Providers/AuthProvider';
import ForgotPassword from './Screens/Auth/ForgotPassword';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { DataProvider } from './Providers/DataProvider';
import Settings from './Screens/Main/Settings';
import DashBoard from './Screens/Main/Dashboard/Dashboard';
import Home from './Screens/Main/Dashboard/Home';
import Nearby from './Screens/Main/Dashboard/Nearby';
import Profile from './Screens/Main/Dashboard/Profile';
import ProductPage from './Screens/Main/ProductPage';
import BusinessPage from './Screens/Main/BusinessPage';
import { BrowserRouter } from 'react-router-dom';
import { useState } from 'react';

const theme = createTheme({
  components: {
    MuiListItem: {
      styleOverrides: {root: {
        borderRadius:8
      }}
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // overflow: 'overlay',
          scrollbarColor: "#6b6b6b transparent",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: 8,
            height: 4
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 25,
            backgroundColor: "#6b6b6b20",
            minHeight: 24,
            // border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',//darkmode
    background:{//darkmode
      default:'#000',//darkmode
      paper:'#151515'//darkmode
    },//darkmode
    primary: {
      main: "#EEF0F1",
    },
    secondary: {
      main: '#309434',
    },
  },
  shape: {
    borderRadius: 25,
  },
});

function App() {
  const [nearbyLoaded, setNearbyLoaded] = useState(false);
  // const [homeLoaded, setHomeLoaded] = useState(false);
  // const [profileLoaded, setProfileLoaded] = useState(false);
  return (
  <ThemeProvider theme={theme}>
    <CssBaseline/>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/SignIn"
            element={<SignIn />}
          />
          <Route path="/SignUp"
            element={<SignUp />}
          />
          <Route path="/ForgotPassword"
            element={<ForgotPassword />}
          />
          <Route path="/"
            element={
              <PrivateRoute>
                <DataProvider>
                  <Outlet />
                </DataProvider>
              </PrivateRoute>
            }
            >
            <Route path="/" element={<DashBoard/>}>
              <Route path="Home" element={<>{nearbyLoaded&&<Nearby hide={true}/>}<Outlet/></>}>
                <Route index element={<Home/>}/>
                <Route path="Business/:businessId" element={<Outlet/>}>
                  <Route index element={<BusinessPage/>}/>
                  <Route path="Product/:productId" element={<ProductPage/>}/>
                </Route>
              </Route>
              <Route path="Nearby"
                element={<Nearby load={setNearbyLoaded} />}
              />
              <Route path="Profile"
                element={<>{nearbyLoaded&&<Nearby hide={true}/>}<Profile/></>}
              />
              <Route path="/"
                element={<Navigate to="/Home" />}
              />
            </Route>
            <Route path="/settings"
              element={<Settings/>}
            />
            <Route path="/Help"
                element={<DashBoard/>}
            />
            <Route path="/"
                element={<DashBoard/>}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
    </ThemeProvider>
  );
}

function PrivateRoute({ children }:any) {
  const {currentUser} = useAuth();
  let location = useLocation();

  if(!currentUser) {
    return <Navigate to= "/SignIn" state={{from: location}}/>;
  }
  return children;
}

export default App;
