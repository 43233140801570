import { AppBar, Box, IconButton, Toolbar, styled, alpha, SwipeableDrawer, Button, Collapse, Autocomplete, TextField } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as Logo } from "../../App/Assets/logo.svg";
import { ReactElement, useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from "../Providers/AuthProvider";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useData } from "../Providers/DataProvider";

function HideOnScroll({children, node, invert=false, vertical=false}:{vertical?:Boolean;invert?:Boolean;node:Node|Window |undefined; children:ReactElement}) {
  const trigger = useScrollTrigger({disableHysteresis:true, target:node});
  
  return (
    <Collapse orientation={!vertical? 'vertical':'horizontal'} in={invert?trigger:!trigger}>
      {children}
    </Collapse>
  );
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    flex: 1,
    // display: { xs: 'none', sm: 'block' },
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.55),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.65),
    },
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(1),
    //   width: 'auto',
    // },
  }));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.05)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.15)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
        borderWidth:'1px'
      },
      '&.Mui-focused:hover fieldset': {
        borderColor: 'rgba(0, 100, 0, 0.15)',
        borderWidth:'1px'
      },
    },
    '& .MuiAutocomplete-inputRoot': {
      '& .MuiAutocomplete-input':{
        padding: 0
      },
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
    //   [theme.breakpoints.up('xs')]: {
    //     width: '12ch',
    //     '&:focus': {
    //       width: '20ch',
    //     },
    //   },
    },
  }));

interface IAppProps {
  scrollNode?: Node|Window|undefined;
  collapsable?: ReactElement;
  // addOn?: ReactElement;
}

export default function MainNavBar ({collapsable, scrollNode/*, addOn*/}: IAppProps) {
  const {logOut}=useAuth();
  const {productAutoComplete}=useData();
  const[drawer, setDrawer] = useState(false);
  const [options, setOptions] = useState<String[]>([])
  // const [value, setValue] = useState<String>('')

  function getOptions(value:String) {
    console.log(value);  
    productAutoComplete(value).then(
      (result)=>{      
        setOptions(result?.map((r)=>r.name)||[]);
      },
      (e)=>{
        console.log(e);
      }
    )    
  }
  
  return (
    <Box>
      <AppBar elevation={0} position="static">
      <HideOnScroll node={scrollNode}>  
        <Toolbar  sx={{display:'flex', justifyContent:'space-between'}}>
          <IconButton
          onClick={()=>setDrawer(true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{display:'flex'}} component={RouterLink} to="/">
          <Logo style={{height:"auto", width: "100px"}}/>
          </Box>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 2 }}
          >
            <ShoppingCartOutlinedIcon />
          </IconButton>
        </Toolbar>
      </HideOnScroll>
      {/* </AppBar> */}
      {/* <AppBar elevation={0} position={'sticky'}> */}
      <Toolbar sx={{display:'flex', justifyContent:'space-between'}}>
        <HideOnScroll node={scrollNode} vertical invert>
        <IconButton
          onClick={()=>setDrawer(true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 1 }}
          >
            <MenuIcon />
          </IconButton>
          </HideOnScroll>
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledAutocomplete
                filterOptions={(x) => x}
                id="Busivisy-Search"
                // value={value}
                freeSolo
                inputMode='search'
                options={options}
                // onChange={(event: any, newValue: any | null)=>{setValue((v)=>newValue||v)}}
                renderInput={(params)=><TextField {...params} placeholder="Search…"/>}
                onInputChange={(event: React.SyntheticEvent, value: string, reason: string)=>{if(reason==="input")getOptions(value);}}
              />
          </Search>
        <HideOnScroll node={scrollNode} vertical invert>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 1 }}
          >
            <ShoppingCartOutlinedIcon />
          </IconButton>
          </HideOnScroll>
        </Toolbar>
      </AppBar>
      {collapsable!==undefined && <HideOnScroll node={scrollNode}>{collapsable}</HideOnScroll>}
      {/* {addOn} */}
      <SwipeableDrawer open={drawer} onOpen={()=>setDrawer(false)} onClose={()=>setDrawer(false)}>
        <Box p={1} sx={{width: 250}}>
          <Button fullWidth variant="contained" color="inherit" onClick={()=>logOut()} >Sign out</Button>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}
