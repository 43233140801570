import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, IconButton, Rating, Skeleton, Typography } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import ShareIcon from '@mui/icons-material/Share';
import { useData } from '../Providers/DataProvider';
import { BSON } from 'realm-web';
import {Waypoint} from 'react-waypoint';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    businessId: BSON.ObjectId
}

export default function ProductsGrid({businessId}: Props): ReactElement {
  const {businessProducts} = useData();
  const [businessP, setBusinessP] = useState<undefined | any[]>()
  const [allLoaded, setAllLoaded] = useState(false)

function ProductSkeleton ({index}:{index?:number}):ReactElement {
  return (
          <Grid item key={`LoadingSkeleton${index}`} xs={6} sm={6} md={3}>
            {(businessP!==undefined)&&<Waypoint onEnter={()=>loadMore()}/>}
            <Card sx={{height:'100%', display:'flex', flexDirection:'column'}}>
              <CardActionArea sx={{flex:1,display:'flex', alignItems:'stretch', flexDirection:'column'}}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={240}
                />
                <CardContent sx={{flex:1}}>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    <Skeleton animation="wave"/>
                  </Typography>
                      <Typography sx={{width:'70%' }}>
                          <Skeleton animation="wave"/>
                      </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{pt:0}}>
                <Skeleton  animation="wave" variant='circular' width={40} height={40} />
              </CardActions>
            </Card>
          </Grid>
          )
  }
  
  useEffect(() => {
    businessProducts(businessId)
      .then(
        (value)=>{
          if(value?.length===0)setAllLoaded(true);
          setBusinessP(value);
        },
        ()=>setBusinessP(undefined)
      )
      .finally(()=>console.log('productsFetched'))
    return () => {
      setBusinessP(undefined);
      setAllLoaded(false);
    }
  }, [])

  function loadMore() {
    // console.log(businessP?.length);

    if(!allLoaded && businessP!=undefined)
      businessProducts(businessId, businessP?.length)
        .then(
          (newValue)=>setBusinessP(
            (oldValue)=>{
              if(newValue?.length==0){
                  setAllLoaded(true);
                  return oldValue;
                }
                else
                return (!oldValue) ? newValue : oldValue.concat(newValue);
            }
          ),
          ()=>console.log('load more failed')      
        )
        .finally(()=>console.log('Loaded more products'))
  }

  return (
      <Grid container spacing={1.5}>
        {/* {JSON.stringify(businessP)} */}
          {businessP?.map((product:any)=>
              <Grid item key={product._id} xs={6} sm={6} md={3}>
                <Card sx={{height:'100%', display:'flex', flexDirection:'column'}}>
                  <CardActionArea
                    component={RouterLink} 
                    to={'Product/' + product._id}
                    sx={{flex:1,display:'flex', alignItems:'stretch', flexDirection:'column'}}
                    state={{
                            ...product,
                            _id : product._id.toString(),
                            businessId : product.businessId.toString()
                          }}
                  >
                    <CardMedia
                      component="img"
                      height="240"
                      image={product.images||"https://source.unsplash.com/random"}
                      alt="Product Image"
                    />
                    <CardContent sx={{flex:1}}>
                      <Typography gutterBottom variant="subtitle1" component="div">
                        {product.name || "-"}
                      </Typography>
                      <Box sx={{/*width: 200,*/ display: 'flex', alignItems: 'center'}}>
                          <Rating
                            sx={{zIndex:0}}
                              name="product-rating"
                              defaultValue={4}
                              // precision={0.25}
                              readOnly
                          />
                          <Box sx={{ ml: 1 }}>
                              {product.qty}
                          </Box>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                  <CardActions sx={{pt:0}}>
                    <IconButton aria-label="share">
                      <ShareIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>) 
            ||
            <ProductSkeleton/>
          }
          {(!allLoaded) && <ProductSkeleton/>}
      </Grid>
    )
}
