import { BottomNavigationAction as MuiBottomNavigationAction, BottomNavigation, styled } from "@mui/material";
import { useEffect, useState } from "react";
import HomeIcon from '@mui/icons-material/Home';
import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'

export interface IBottomNavProps {
}

const BottomNavigationAction = styled(MuiBottomNavigationAction)(({ theme }) => ({
  '&.Mui-selected' :  {
        color: '#054608',
        backgroundColor: theme.palette.mode==='dark'?'#aaa':'#e5e5e5',
      }
})
)

function getValue(route:string) {
  // const route = window.location.pathname;
  switch (route) {
    case "/Home":
      return "/Home";
    case "/Nearby":
      return "/Nearby";
    case "/Profile":
      return "/Profile";
    default:
      return undefined
  }
}

export default function BottomNav (props: IBottomNavProps) {
  const navigate = useNavigate();
  const [value, setValue] = useState<"/Home"|"/Nearby"|"/Profile"|undefined>('/Home');
  let location:any|undefined = useLocation();
  
  useEffect(() => {
    
    setValue(OldValue=>getValue(location.pathname)||OldValue);
    return () => {
      setValue('/Home')
    }
  }, [location])

  return (
    <BottomNavigation
      sx={{ position: 'sticky', bottom: 0, left: 0, right: 0 }}
          value={value}
      onChange={(event, newValue)=>navigate(newValue)}
    >
      <BottomNavigationAction value={"/Home"} label="Home" icon={<HomeIcon />} />
      <BottomNavigationAction value={"/Nearby"} label="Nearby" icon={<RoomIcon />} />
      <BottomNavigationAction value={"/Profile"} label="Profile" icon={<PersonIcon />} />
    </BottomNavigation>
  );
}
